@font-face {
  font-family: 'SpyAgency';
  src: local('SpyAgency'), url(./assets/fonts/spyagency.ttf) format('truetype');
}

@font-face {
  font-family: 'conthrax-rg';
  src: url('./assets/fonts/conthrax_regular_macroman/conthrax-rg-webfont.woff2') format('woff2'),
  url('./assets/fonts/conthrax_regular_macroman/conthrax-rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background: black;
  font-family: conthrax-rg;
  //height: 100vh !important;
  height: 100% !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
}

* {
  --tw-text-opacity: 1;
  //--tw-max-width: 700px;
  --af-color-theme-red: #e30c17;
  --af-color-grey: #ccc;
  --af-color-grey-1: #ccc;
  --af-color-grey-2: #9c9c9c;
  --af-color-grey-3: #222;
  --af-color-grey-4: #1f1f1f;
  --af-color-grey-5: #151515;
  --af-color-grey-6: #3c3f42;
  --af-color-grey-7: #212427;
  --af-color-grey-8: #4f5255;
  --af-color-tuerkis-0: #40E0D0;
  --af-color-tuerkis-1: #30D5C8;
  --af-color-tuerkis-2: #00CED1;
  --af-color-blue-0: #0000FF; // Blau: #0000FF
  --af-color-blue-1: #87CEEB; // Himmelblau: #87CEEB
  --af-color-blue-2: #00008B; // Dunkelblau: #00008B
  --af-color-blue-3: #4169E1; // Königsblau: #4169E1
  --af-color-blue-4: #000080; // Marineblau: #000080
  --af-color-white-default: #ddd;
  --af-color-white: #e3e3e3;
  --af-color-footer-grey: #353535;
  color: var(--af-color-white-default);
}

a {
  text-decoration: underline;
  text-decoration-color: var(--af-color-theme-red);
}

.font-arrowfight {
  font-family: SpyAgency !important;
}

.font-arrowhacks {
  font-family: conthrax-rg !important;
}

.page-content {
  flex: 1;
  //padding-top: 100px;
  padding: 0 10px;

}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.main-wrapper {
  margin: 20px auto;
  width: 80vw;
}

.main-wrapper-content {
  margin: 20px auto;
  width: 80vw;
}

.main-content {
  padding-top: 100px;
}

.col-left {
  margin: 10px;
  width: 30%;
}

//.col-left:hover {
//  background-color: var(--af-color-grey-4);
//  cursor: pointer;
//}

.col-right {
  margin: 10px;
  width: 30%;
}

//.col-right:hover {
//  background-color: var(--af-color-grey-4);
//  cursor: pointer;
//}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.col-center {
  text-align: center;
}

.background-black-l {
  background-color: var(--af-color-grey-4);
}

.suspense_logo {
  display: flex;
  justify-content: center;
  //padding-top: 49vh;
  padding-top: 433px;
  height: 200px !important;
}

.social-logo-icon-size-m {
  height: 25px !important;
}

.zone-icon-size {
  height: 150px;
}

.icon-404-size {
  height: 500px;
}

.icon-size {
  height: 200px;
}

.appbar-top {
  z-index: 1000 !important;
  position: fixed !important;
  width: 100%;
  background-color: var(--af-color-theme-red) !important;
  height: 20px;
}

.appbar-bottom {
  min-height: 50px;
  //padding: 20px 0 0 0;
}
.appbar-bottom-with-cookie-bar {
  min-height: 100px;
  padding: 50px 0 0 0;
}

.CookieConsent {
  max-height: 100px;
}

.switcher-top {
  display: flex;
  justify-content: end !important;
  padding-top: 25px;
}

.switcher {
  //padding-top: 13px;
  z-index: 1000;
  position: fixed !important;
  display: flex;
  justify-content: flex-end;
  font-family: conthrax-rg;
  background-color: black;
  border: unset;
  font-size: 1.1rem;
  margin: 0 10px;
}

.border-radius-xl {
  border-radius: 50px 50px 50px 50px;
}

.arrowfight_logo {
  height: 35px;
  cursor: pointer;
}

.copyright_icon {
  height: 15px !important;
  color: white;
}

.k-appbar-section {
  color: white !important;
  font-size: .85rem;
}

.border-line-red {
  border-bottom-color: red;
  border-bottom-style: solid;
  width: 100%;
  border-bottom-width: 0.5px;
  padding: 5px !important;
}

p {
  margin: 10px 0;
}

h2 {
  color: white;
}

ul {
  font-size: 1.0rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  //display: flex;
}

li {
  margin: 0 15px;
}

li:hover {
  cursor: pointer;
  color: #84cef1;
  //color: gray;
}

.col {
  flex: 0 0 50%;
  margin-left: 0;
  max-width: 45%;
  font-size: 1.2em;
  padding-left: 30px;
}

.col-full {
  flex: 0 0 100%;
  margin-left: 0;
  max-width: 100vw;
  font-size: 1.2em;
  padding-left: 30px;
}


.button-link {
  font-family: conthrax-rg;
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
}

.text-size-xxs {
  font-size: 0.8rem;
}

.text-size-xs {
  font-size: 1.0rem;
}

.text-size-s {
  font-size: 1.2rem;
}

.text-size-m {
  font-size: 1.4rem;
}

.text-size-l {
  font-size: 1.6rem;
}

.text-size-xl {
  font-size: 1.8rem;
}

.text-size-xxl {
  font-size: 2.0rem;
}

.text-size-3xl {
  font-size: 2.2rem;
}

.text-size-4xl {
  font-size: 3.0rem;
}

.text-size-5xl {
  font-size: 2.6rem;
}

.text-size-6xl {
  font-size: 2.8rem;
}

.text-color-grey-2 {
  color: var(--af-color-grey-2)
}

.text-color-blue {
  color: var(--af-color-theme-blue)
}

.text-color-blue-1 {
  color: var(--af-color-blue-1)
}

.text-color-red {
  color: var(--af-color-theme-red)
}

.text-color-red:hover {
  color: white;
}

.text-uppercase {
  text-transform: uppercase;
}

.lp-zone-text-title {
  color: #fff;
  font-size: 1.2rem;
  font-variant: petite-caps;
  font-weight: bold;
}

.lp-zone-text-content {
  font-size: 1.0rem;
}

.space-between {
  justify-content: space-between !important;
}

.margin-left-m {
  margin: 0 5px 0 0;
}

.margin-left-l {
  margin: 0 10px 0 0;
}

.margin-left-xl {
  margin: 0 15px 0 0;
}

.margin-left-right-m {
  margin: 0 5px;
}

.margin-left-right-l {
  margin: 0 10px;
}

.margin-top-m {
  margin: 5px 0 0 0 !important;
}

.margin-top-l {
  margin: 10px 0 0 0 !important;
}

.margin-top-xl {
  margin: 20px 0 0 0;
}

.margin-top-xxl {
  margin: 30px 0 0 0;
}

.margin-top-3-5xl {
  margin: 100px 100px 0 100px;
}

.margin-right-m {
  margin: 0 5px 0 0;
}

.margin-right-l {
  margin: 0 10px 0 0;
}

.margin-right-xl {
  margin: 0 15px 0 0;
}

.margin-bottom-m {
  margin: 0 0 5px 0 !important;
}

.margin-bottom-l {
  margin: 0 0 10px 0 !important;
}

.padding-medium {
  padding: 10px 10px;
}

.padding-top-l {
  padding: 10px 0 0 0;
}

.padding-top-xl {
  padding: 15px 0 0 0;
}

.padding-top-xxl {
  padding: 20px 0 0 0;
}

.padding-top-3xl {
  padding: 25px 0 0 0;
}

.padding-top-4xl {
  padding: 30px 0 0 0;
}

.padding-top-landingpage {
  padding: 100px 0 0 0;
}

.padding-top-down-m {
  padding: 5px 0 5px 0 !important;
}
.padding-top-down-xl {
  padding: 15px 0 15px 0 !important;
}
.padding-top-down-5xl {
  padding: 50px 0 50px 0 !important;
}
.padding-top-down-l {
  padding: 10px 0 10px 0 !important;
}

.padding-bottom-m {
  padding: 0 0 5px 0;
}

.padding-bottom-l {
  padding: 0 0 10px 0;
}

.padding-right-m {
  padding: 0 5px 0 0;
}

.padding-right-l {
  padding: 0 10px 0 0;
}

.padding-right-xl {
  padding: 0 15px 0 0;
}

.padding-right-xxl {
  padding: 0 20px 0 0;
}

.padding-right-3xl {
  padding: 0 30px 0 0;
}

.padding-right-4xl {
  padding: 0 40px 0 0;
}

.padding-left-m {
  padding: 0 0 0 5px;
}

.padding-left-l {
  padding: 0 0 0 10px;
}

.padding-left-with-beta {
  padding-left: 65px;
}

.padding-left-xl {
  padding: 0 0 0 15px;
}

.padding-left-xxl {
  padding: 0 0 0 20px;
}

.padding-both-clean {
  padding: 0 0 !important;
}

.padding-left-right-m {
  padding: 0 5px !important;
}

.padding-left-right-l {
  padding: 0 10px !important;
}

.padding-left-right-xl {
  padding: 0 15px !important;
}

.padding-left-right-xxl {
  padding: 0 20px !important;
}

.padding-left-right-ac {
  padding: 0 50px !important;
}

.padding-l {
  padding: 10px 10px !important;
}

.padding-xl {
  padding: 15px 15px !important;
}

.padding-xxl {
  padding: 20px 20px !important;
}

.padding-left-right-stepper {
  padding: 0 8% 0 7%;
}

.row-direction {
  display: flex;
  flex-direction: row;
  //overflow-wrap: break-word;
  //word-break: break-all;
}

.flex-wrap {
  flex-wrap: wrap;
  flex-shrink: 1 !important;
}

.column-direction {
  display: flex;
  flex-direction: column !important;
}

.align-self-normal {
  align-self: normal;
}

.text-align-start {
  text-align: start;
}

.text-align-center {
  text-align: center;
}

.text-align-end {
  text-align: end;
}

.text-bold {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.full-width-xl {
  width: 145%;
  margin-left: -30px;
}

.width-l {
  width: 120px;
  min-width: 120px;
}

.fullCenter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.k-button-text {
  font-size: 1.6rem;
  min-width: 200px;
}

.k-button {
  border-radius: 30px !important;
  padding: 10px 20px !important;
}

.beta-chip-style {
  cursor: unset !important;
  font-size: .8rem !important;
  font-weight: bold;
  background-color: var(--af-color-theme-red) !important;
  padding: 0 !important;
}

.new-chip-style {
  border-color: #006D6B !important;
  cursor: unset !important;
  font-size: .8rem !important;
  font-weight: bold;
  background-color: var(--af-color-tuerkis-2) !important;
  padding: 0 !important;
}

.free-chip-style {
  border-color: white !important;
  cursor: unset !important;
  font-size: 1.0rem !important;
  background-color: var(--af-color-blue-1) !important;
  padding: 0 !important;
  transform: rotate(-10deg);
}

.free-chip-style > span:nth-child(1) > span:nth-child(1) {
  color: black;
}
.new-chip-style > span:nth-child(1) > span:nth-child(1) {
  color: black;
}

/*Smartphones*/
/*#######################################*/
/*#######################################*/
/*#######################################*/
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 667px) {

  .main-wrapper-content {
    width: 100vw;
  }

  .main-wrapper {
    margin: auto;
    width: 95vw;
  }

  .main-content {
    padding-top: 45px;
  }

  .suspense_logo {
    height: 130px !important;
    padding-top: 30vh;
  }

  .padding-top-landingpage {
    padding: 70px 0 0 0;
  }

  .padding-top-down-5xl {
    padding: 20px 0 20px 0 !important;
  }

  .margin-top-3-5xl {
    margin: 70px 0 20px 0;
  }

  .appbar-bottom-with-cookie-bar {
    min-height: 250px;
    padding: 50px 0 0 0;
  }

  .CookieConsent {
    min-height: 250px;
  }

  .border-radius-xl {
    border-radius: 20px 20px 20px 20px;
  }

  .k-button-text {
    font-size: 1.4rem;
  }

  .icon-size {
    width: 120px;
  }

  .icon-404-size {
    height: 300px;
  }

  .text-size-s {
    font-size: .8rem;
  }

  .text-size-xxl {
    font-size: 1.4rem;
  }

  .text-size-3xl {
    font-size: 1.8rem;
  }

  .text-size-4xl {
    font-size: 2.0rem;
  }

  .text-size-5xl {
    font-size: 2.2rem;
  }

  .text-size-6xl {
    font-size: 2.6rem;
  }

  .col-left {
    width: unset;
  }

  .col-right {
    width: unset;
  }

  .zone-icon-size {
    height: 120px;
  }

}
